import React from "react";
import "./AppraiseOrderItem.less";
import _ from "lodash";
import { Card, Tag } from "antd";
import AspectImage from "../AspectImage/AspectImage";
import { qiniuImageView } from "../../utils/helpers";
import IconFont from "../IconFont/IconFont";
import { qrcodeModal } from "../QRModal/QRModal";
import { isMobile } from "react-device-detect";
var AppraiseOrderItem = function (_a) {
    var appraiseOrder = _a.appraiseOrder;
    var objectId = _.get(appraiseOrder, "objectId");
    var cover = qiniuImageView(_.get(appraiseOrder, "cover"), 640);
    var authBrand = _.get(appraiseOrder, "authBrand");
    var authPlan = _.get(appraiseOrder, "authPlan");
    var authType = _.get(appraiseOrder, "authType");
    var counts = _.get(appraiseOrder, "counts");
    var first_submit_time = _.get(counts, "first_submit_time");
    var payload_count = _.get(counts, "payload_count");
    var photo_count = _.get(counts, "photo_count");
    var isReal = _.get(appraiseOrder, "appraisal.pass") == true;
    var usedMinutes;
    if (first_submit_time) {
        var usedSeconds = _.get(appraiseOrder, "appraisal.date", 0) - first_submit_time;
        usedMinutes = Math.floor(usedSeconds / 60 / 1.2);
    }
    return (React.createElement(Card, { className: "appraise-item-card", hoverable: true, onClick: function () {
            if (isMobile) {
                document.location.href = "http://cloud.ilux.ai/urlscheme/weapp_appraise";
            }
            else {
                qrcodeModal({
                    platform: "appraise",
                    scene: objectId,
                    page: "pages/appraise/room",
                });
            }
        }, cover: React.createElement(AspectImage, { src: cover, heightRatio: (3 / 4) * 100 + "%" }) },
        React.createElement(Card.Meta, { title: React.createElement("div", null,
                React.createElement("h3", { className: "brand-type" },
                    authBrand,
                    "\u300C",
                    authType,
                    "\u300D"),
                React.createElement("h4", { className: "plan" }, authPlan)), description: React.createElement("div", { className: "description" },
                React.createElement(Tag, { className: "appraisal-badge", color: isReal ? "#d69f9f" : "#666666" },
                    React.createElement("div", { className: "inner-wrapper" },
                        isReal ? (React.createElement(IconFont, { name: "icon-assessed-badge", size: "1.5em" })) : (React.createElement(IconFont, { name: "icon-reeor", size: "1.4em" })),
                        "\u9274\u5B9A\u4E3A",
                        isReal ? "真" : "假"))) })));
};
export default AppraiseOrderItem;
