import { Card } from "antd";
import _ from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";
import { conditionParse, qiniuImageView } from "../../utils/helpers";
import IconFont from "../IconFont/IconFont";
import "./ShopShowcaseItem.less";
var ShopShowcaseItem = function (_a) {
    var showcaseItem = _a.showcaseItem;
    var history = useHistory();
    var id = _.get(showcaseItem, "id");
    var objectId = _.get(showcaseItem, "objectId");
    var name = _.get(showcaseItem, "name");
    var description = _.get(showcaseItem, "description");
    var price = _.get(showcaseItem, "price");
    var displayPrice = "";
    try {
        displayPrice = price.toFixed(0);
    }
    catch (err) { }
    var sold = _.get(showcaseItem, "sold", false);
    var brand = _.get(showcaseItem, "brand", {});
    var brandCnName = _.get(brand, "cnName");
    var brandName = _.get(brand, "name");
    var brandId = _.get(brand, "objectId");
    var cover_url = qiniuImageView(_.get(showcaseItem, "cover_url", ""), 750);
    var condition = conditionParse(_.get(showcaseItem, "condition", 0));
    var category = _.get(showcaseItem, "category");
    var detailUrl = "/shopitem/" + id;
    return (React.createElement(Card, { cover: React.createElement("a", { href: detailUrl, title: brandName + " " + brandCnName + " " + name },
            React.createElement("img", { src: cover_url, alt: brandName + " " + brandCnName + " " + name, width: "100%" })), 
        // hoverable
        className: "showcase-item-card" },
        React.createElement(Card.Meta, { title: React.createElement("div", null,
                React.createElement("a", { href: detailUrl, title: brandName + " " + brandCnName + " " + name },
                    React.createElement("h3", { className: "brand-name" },
                        brandName,
                        " ",
                        brandCnName)),
                React.createElement("a", { href: detailUrl, title: brandName + " " + brandCnName + " " + name },
                    React.createElement("h4", { className: "item-name" }, name))), description: React.createElement("div", { className: "description" },
                "\u300C",
                condition,
                "\u300D",
                description) }),
        React.createElement("div", { className: "price-wrapper" },
            React.createElement(IconFont, { name: "icon-discount" }),
            React.createElement("span", { className: "price" }, displayPrice))));
};
export default ShopShowcaseItem;
