var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Layout, Modal, Dropdown, Button, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { Link, matchPath, Route, Switch, useLocation, useHistory } from "react-router-dom";
import "./MainFrame.less";
import routes from "./routes";
import { ServerRPC } from "../api/api";
import { signWxJssdk } from "../utils/helpers";
import { globalSlice } from "../redux/store";
import IconFont from "../components/IconFont/IconFont";
var Header = Layout.Header, Content = Layout.Content, Footer = Layout.Footer;
var links = [
    {
        label: "关于我们",
        url: "/about",
    },
    {
        label: "联系客服",
        action: function () {
            Modal.success({
                title: "添加客服微信",
                content: (React.createElement("div", null,
                    React.createElement("div", null, "\u626B\u7801\u6216\u641C\u7D22\u6DFB\u52A0\u5BA2\u670D\u5FAE\u4FE1\u53F7: iLuxAI"),
                    React.createElement("img", { src: "https://file.ilux.ai/6c99b0c7286c5d610fba.jpeg", style: { width: "100%" } }))),
            });
        },
    },
];
var MainFrame = function () {
    var history = useHistory();
    var location = useLocation();
    var _a = useState(""), selectedMenuKey = _a[0], setSelectedMenuKey = _a[1];
    useEffect(function () {
        if (location) {
            var matchedRoute = routes.find(function (r) { return matchPath(location.pathname, r); });
            if (matchedRoute) {
                setSelectedMenuKey(matchedRoute.key);
            }
        }
    }, [location]);
    var Logo = function (_a) {
        var _b = _a.eng, eng = _b === void 0 ? false : _b;
        return (React.createElement("a", { href: "/", title: "iLux\u800D\u5927\u724C\u7814\u7A76\u6240", className: "logo-a" },
            React.createElement("img", { className: "logo", src: eng ? "https://file.ilux.ai/7c4a605700844a01c625.png" : "https://file.ilux.ai/43bbdd596d4ba92045c6.png" })));
    };
    return (React.createElement(Layout, { className: "mainframe" },
        React.createElement(Header, { className: "header" },
            React.createElement("div", { className: "header-container" },
                React.createElement(Logo, null),
                React.createElement("div", { className: "menu" }, routes
                    .filter(function (r) { return !r.hideFromMenu; })
                    .map(function (m, i) {
                    if (selectedMenuKey == "appraise") {
                        return "";
                    }
                    return (React.createElement("a", { key: m.key, className: "menu-item " + (selectedMenuKey.includes(m.key) ? "selected" : ""), href: m.path, title: m.label }, m.label));
                })),
                React.createElement(Dropdown, { className: "mobile-menu", trigger: ["click"], overlay: React.createElement(Menu, { onClick: function (e) {
                            var route = routes.find(function (r) { return r.key == e.key; });
                            if (route === null || route === void 0 ? void 0 : route.path) {
                                history.push(route.path);
                            }
                        }, style: { minWidth: "30vw" } }, routes
                        .filter(function (r) { return !r.hideFromMenu; })
                        .map(function (m, i) { return (React.createElement(Menu.Item, { key: m.key }, m.label)); })) },
                    React.createElement(Button, { className: "mobile-menu-drop-btn" },
                        React.createElement(IconFont, { name: "icon-menu", size: "1.4em", color: "#333" }))))),
        React.createElement(Content, { className: "content" },
            React.createElement(Switch, null, routes.map(function (r) { return (React.createElement(Route, { key: r.key, path: r.path, exact: true, component: r.component })); }))),
        React.createElement(Footer, { className: "footer" },
            React.createElement("div", { className: "link-grid" }, links.map(function (a, i) {
                return a.action ? (React.createElement("a", { key: i, onClick: a.action }, a.label)) : (React.createElement(Link, { key: i, to: a.url }, a.label));
            })),
            React.createElement("div", null,
                "\u00A9 2017 - ",
                new Date().getFullYear(),
                " iLux\u00AE \u5E7F\u5DDE\u7231\u4E3D\u793E\u79D1\u6280\u6709\u9650\u516C\u53F8 "),
            React.createElement("div", { style: {
                    display: "flex",
                    flexFlow: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                } },
                React.createElement("span", { style: { marginLeft: 16 } },
                    React.createElement("span", null,
                        "\u5B98\u7F51 ",
                        React.createElement("a", { href: "https://ilux.ai" }, "iLux.ai"),
                        " |"),
                    " ",
                    React.createElement("img", { src: "/images/jh.jpg" }),
                    React.createElement("a", { href: "http://beian.miit.gov.cn", target: "_blank" }, "\u7CA4ICP\u590717136441\u53F7"))))));
};
var serverFetch = function (AV, currentUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var server_rpc, p0, wxjsdkConf;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                console.log("currentUrl", currentUrl);
                server_rpc = new ServerRPC(AV);
                p0 = signWxJssdk(server_rpc, currentUrl);
                return [4 /*yield*/, Promise.all([p0])];
            case 1:
                wxjsdkConf = (_a.sent())[0];
                return [2 /*return*/, [globalSlice.actions.setWxJssdkConfig(wxjsdkConf)]];
        }
    });
}); };
MainFrame.serverFetch = serverFetch;
export default MainFrame;
