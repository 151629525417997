import React from "react";
import "./Highlights.less";
import IconFont from "../IconFont/IconFont";
var Highlights = function (_a) {
    var highlightIcons = _a.highlightIcons;
    return (React.createElement("div", { className: "highlights-container" },
        React.createElement("div", { className: "highlights-wrapper" }, highlightIcons.map(function (h) { return (React.createElement("div", { className: "grid-item", key: h.title },
            React.createElement("div", { className: "icon-wrapper" },
                React.createElement(IconFont, { name: h.icon, color: "white", size: "28px" })),
            React.createElement("div", { className: "title" }, h.title))); }))));
};
export default Highlights;
