var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import "./IconFont.less";
var IconFont = function (_a) {
    var name = _a.name, size = _a.size, color = _a.color, style = _a.style, spin = _a.spin;
    var iconName = name.startsWith("icon-") ? name : "icon-" + name;
    var spinClass = !!spin ? "spin" : "";
    return (React.createElement("i", { className: "iconfont " + iconName + " " + spinClass, style: __assign({ fontSize: typeof size == "number" ? size + "px" : size ? size : "inherit", color: color ? color : "inherit", lineHeight: "1em" }, style) }));
};
export default IconFont;
