var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Affix, Button, Modal, Steps, Typography } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import API, { ServerRPC } from "../../api/api";
import AppraiseOrderGrid from "../../components/AppraiseOrderGrid/AppraiseOrderGrid";
import IconFont from "../../components/IconFont/IconFont";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { appraiseSlice, globalSlice } from "../../redux/store";
import { qiniuImageView, wxConfInit } from "../../utils/helpers";
import { useQuerySearch } from "../../utils/hooks";
import { AppraiseHighlights } from "../Home/Home";
import "./Appraise.less";
export var createWeAppHtml = function () {
    return {
        __html: "<wx-open-launch-weapp id=\"launch-btn\" username=\"gh_ad6aabfc155d\" path=\"pages/index/index.html\">\n\t\t\t<template>\n\t\t\t\t<style> \n\t\t\t\t.jump-btn {\n\t\t\t\t\tbackground-color: #d69f9f;\n\t\t\t\t\tcolor: white;\n\t\t\t\t\tborder: 1px solid rgba(0,0,0,0.05);\n\t\t\t\t\tborder-radius: 22px;\n\t\t\t\t\theight: 44px;\n\t\t\t\t\tpadding: 0 24px;\n\t\t\t\t\tfont-size: 1.2em;\n\t\t\t\t}</style>\n\t\t\t<button class=\"jump-btn\">\u6253\u5F00\u5C0F\u7A0B\u5E8F</button>\n\t\t\t</template>\n\t\t\t</wx-open-launch-weapp>",
    };
};
var Appraise = function () {
    var location = useLocation();
    var queries = useQuerySearch(location);
    var weappOpenLink = "https://cloud.ilux.ai/urlscheme/weapp_appraise";
    // console.log(`weappOpenLink`, weappOpenLink);
    var iphone_screen_shot_pc = qiniuImageView("https://file.ilux.ai/884967bf19dcc89bb677/iphone-xs-with-macbook-air-in-the-background.jpg", 1024);
    var iphone_screen_shot = qiniuImageView("https://file.ilux.ai/8a7ad496f345f736fa50/woman-holding-iphone-x%20%281%29.png", 1024);
    var homePageScreenShot = isMobile
        ? iphone_screen_shot
        : iphone_screen_shot_pc;
    var results_screen_shot_a = qiniuImageView("https://file.ilux.ai/63f73ca0913452cc34f3/pointing-on-iphone-x-in-front-of-the-shop.jpg", 1024);
    var results_screen_shot = qiniuImageView("https://file.ilux.ai/9f7d8bf386cf48d64784/appraise-result-ss.jpg", 1024);
    var zj_cert = "https://file.ilux.ai/MjbO8X8cQ7hJw64RMVfmKKeepFTewQk6/bagCertificate.png";
    var zj_cert_real = qiniuImageView("https://file.ilux.ai/kVvyJiXgnJ2cd6y7lfS7fcLthlzagNI1/appraisal_image.jpeg", 600);
    var zj_tag = "https://file.ilux.ai/5DomrXQBTtBmt07Y1iNF0FOc7sWCR0Qc/zj_tag.jpeg";
    var appraiseOrders = useSelector(function (state) { return _.get(state, "appraise.appraiseOrders", []); }) || [];
    var wxJssdkConfig = useSelector(function (state) {
        return _.get(state, "global.wxJssdkConfig");
    });
    useEffect(function () {
        wxConfInit({
            wxJssdkConfig: wxJssdkConfig,
            shareTitle: "鉴定神器",
            shareDesc: "专业奢侈品鉴定小程序 | iLux出品",
            shareImage: qiniuImageView("https://file.ilux.ai/468a4b1bf4b72f32862d.png", 500),
        });
    }, [wxJssdkConfig]);
    var getMobileDetect = function (userAgent) {
        var isBaiduApp = function () { return Boolean(userAgent.match(/baidu/i)); };
        var isAndroid = function () { return Boolean(userAgent.match(/Android/i)); };
        var isIos = function () { return Boolean(userAgent.match(/iPhone|iPad|iPod/i)); };
        var isOpera = function () { return Boolean(userAgent.match(/Opera Mini/i)); };
        var isWindows = function () { return Boolean(userAgent.match(/IEMobile/i)); };
        var isSSR = function () { return Boolean(userAgent.match(/SSR/i)); };
        var isMobile = function () {
            return Boolean(isAndroid() || isIos() || isOpera() || isWindows());
        };
        var isDesktop = function () { return Boolean(!isMobile() && !isSSR()); };
        return {
            isBaiduApp: isBaiduApp,
            isMobile: isMobile,
            isDesktop: isDesktop,
            isAndroid: isAndroid,
            isIos: isIos,
            isSSR: isSSR,
        };
    };
    var useMobileDetect = function () {
        var userAgent = typeof navigator === "undefined" ? "SSR" : navigator.userAgent;
        return getMobileDetect(userAgent);
    };
    var MinappCode = function () {
        var isMobile = useMobileDetect().isMobile();
        var isBaiduApp = useMobileDetect().isBaiduApp();
        return (React.createElement("div", { className: "minapp-code" },
            React.createElement("div", { className: "text" }, isMobile ? (
            // <div
            //   style={{
            //     alignSelf: "center",
            //     width: "100%",
            //     display: "flex",
            //     justifyContent: "center",
            //     alignItems: "center",
            //     flexFlow: "column",
            //   }}
            // >
            //   <Button
            //     type="primary"
            //     size="large"
            //     onClick={() => {
            //       document.location.href =
            //         "baiduboxapp://swan/jBWWXVmIqB53SoqYdob2aNxtTy3e39Q6/pages/index/index";
            //     }}
            //     style={{
            //       display: "flex",
            //       flexFlow: "row",
            //       alignItems: "center",
            //     }}
            //   >
            //     <IconFont name="icon-success" size="1.3em" /> 马上开始鉴定
            //   </Button>
            // </div>
            React.createElement("div", { style: {
                    alignSelf: "center",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexFlow: "column",
                } },
                React.createElement("img", { className: "code", src: "https://file.ilux.ai/6c8b3a2a3ea9b0112e12/appraise-qrcode.png", width: 128, height: 128 }),
                React.createElement("div", null, "\u5FAE\u4FE1\u626B\u7801"),
                React.createElement("div", null,
                    "\u6216\u6253\u5F00\u5FAE\u4FE1\u641C\u7D22",
                    " ",
                    React.createElement(Typography.Text, { copyable: {
                            text: "鉴定神器",
                        }, children: React.createElement("span", { style: { color: "black" } }, "\"\u9274\u5B9A\u795E\u5668\"") }),
                    " ",
                    "\u5C0F\u7A0B\u5E8F"),
                React.createElement(Button, { type: "primary", size: "large", onClick: function () {
                        keywordClickThrough();
                        document.location.href = weappOpenLink;
                    }, style: {
                        display: "flex",
                        flexFlow: "row",
                        alignItems: "center",
                    } },
                    React.createElement(IconFont, { name: "icon-success", size: "1.3em" }),
                    " \u9A6C\u4E0A\u5F00\u59CB\u9274\u5B9A"))) : (React.createElement(React.Fragment, null,
                React.createElement("img", { className: "code", src: "https://file.ilux.ai/6c8b3a2a3ea9b0112e12/appraise-qrcode.png", width: 128, height: 128 }),
                React.createElement("div", null, "\u5FAE\u4FE1\u626B\u7801"),
                React.createElement("div", null,
                    "\u6216\u6253\u5F00\u5FAE\u4FE1\u641C\u7D22",
                    " ",
                    React.createElement(Typography.Text, { copyable: {
                            text: "鉴定神器",
                        }, children: React.createElement("span", { style: { color: "black" } }, "\"\u9274\u5B9A\u795E\u5668\"") }),
                    " ",
                    "\u5C0F\u7A0B\u5E8F"))))));
    };
    var previewImage = function (url, title) {
        if (title === void 0) { title = "预览图片"; }
        Modal.success({
            icon: null,
            title: title,
            content: React.createElement("img", { src: url, width: "100%" }),
            className: "preview-modal",
            okText: "关闭",
        });
    };
    var keywordClickThrough = function () {
        try {
            var keyword = Object.fromEntries(location.search
                .replace("?", "")
                .split("&")
                .map(function (a) { return a.split("="); })).keyword;
            // const api = `http://localhost:3000/web/trace`;
            var api = "https://cloud.ilux.ai/web/trace";
            console.log(api);
            fetch(api, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                body: JSON.stringify({ keyword: keyword }),
            }).then(function (res) {
                console.log(res);
            });
        }
        catch (err) {
            console.log(err);
        }
    };
    var steps = [
        {
            title: "选择鉴定方式",
            icon: "icon-cart-Empty",
        },
        {
            title: "拍摄鉴定物品",
            icon: "icon-scanning",
        },
        {
            title: "获得鉴定证书",
            icon: "shouquanshu",
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "page nopad" },
            React.createElement("div", { className: "header-image-container" },
                React.createElement("img", { src: results_screen_shot_a, width: "100%" }))),
        React.createElement("div", { className: "page" },
            React.createElement(SectionTitle, { title: "\u9274\u5B9A\u795E\u5668: \u4E70\u5962\u4F88\u54C1\u4E0D\u518D\u88AB\u9A97", subtitle: React.createElement("div", { style: { textAlign: "center" } },
                    React.createElement("div", null, "\u6700\u4E13\u4E1A\u7684\u5962\u4F88\u54C1\u9274\u5B9A\u5FAE\u4FE1\u5C0F\u7A0B\u5E8F"),
                    React.createElement("div", null, "\u6743\u5A01\u9274\u5B9A\u5E08\u56E2\u961F+AI\u79D1\u6280\u8054\u624B\u540A\u6253\u5047\u8D27"),
                    React.createElement("div", null, "\u5728\u7EBF\u62CD\u7167\u5373\u53EF\u9274\u5B9A\uFF0C\u968F\u65F6\u4E3A\u4F60\u4FDD\u9A7E\u62A4\u822A")) }),
            React.createElement(MinappCode, null),
            React.createElement("div", { className: "flow-chart-wrapper" },
                React.createElement(Steps, { type: "navigation", status: "finish", current: -1 }, steps.map(function (s) { return (React.createElement(Steps.Step, { key: s.title, status: "finish", title: s.title, icon: React.createElement(IconFont, { name: s.icon, size: "32px" }) })); }))),
            React.createElement(SectionTitle, { title: "\u6743\u5A01\u9274\u5B9A\u56E2\u961F", subtitle: React.createElement("div", { style: { textAlign: "center" } },
                    React.createElement("div", null, "\u4E2D\u65E5\u53CC\u91CD\u8BA4\u8BC1 \u00B7 \u8D8515\u5E74\u5962\u4F88\u54C1\u9274\u5B9A\u7ECF\u9A8C"),
                    React.createElement("div", null, "\u914D\u5408\u667A\u80FD\u5927\u6570\u636E\uFF0C\u9274\u5B9A\u66F4\u7CBE\u51C6")) }),
            React.createElement("div", { className: "home-screen-shot-wrapper" },
                React.createElement("img", { alt: "\u5962\u4F88\u54C1\u9274\u5B9A \u9274\u5B9A\u795E\u5668 \u5C0F\u7A0B\u5E8F", src: iphone_screen_shot, className: "home-screen-shot", onClick: function () { return previewImage(results_screen_shot, "鉴定证书效果"); } }),
                React.createElement("img", { alt: "\u5962\u4F88\u54C1\u9274\u5B9A \u9274\u5B9A\u795E\u5668 \u5C0F\u7A0B\u5E8F", src: results_screen_shot_a, className: "cert-image", width: "100%" })),
            React.createElement(AppraiseHighlights, null),
            React.createElement(SectionTitle, { title: "\u5B9E\u7269\u9274\u5B9A: \u4E2D\u68C0\u8BC1\u4E66/\u4E2D\u68C0\u6EAF\u6E90\u6807\u7B7E", subtitle: React.createElement("div", { style: { textAlign: "center", marginBottom: 16 } },
                    "\u9009\u62E9",
                    React.createElement("b", null, "\u5B9E\u7269\u9274\u5B9A"),
                    "\u53EF\u83B7\u5F97\u4E2D\u68C0\u9274\u5B9A\u8BC1\u4E66\uFF0C\u6216\u4E2D\u68C0\u9274\u5B9A\u6EAF\u6E90\u6807\u7B7E",
                    React.createElement("br", null),
                    "\u4E2D\u68C0\u9274\u5B9A\u6EAF\u6E90\u6807\u7B7E\u6837\u5F0F\u662F\u7531\u4E2D\u68C0\u96C6\u56E2\u6253\u9020\u7684\u5962\u4F88\u54C1\u9274\u5B9A\u6EAF\u6E90\u9632\u4F2A\u7CFB\u7EDF\uFF0C\u7ECF\u8FC7\u9274\u5B9A\u6EAF\u6E90\u7684\u5962\u4F88\u54C1\u90FD\u4F1A\u6709\u552F\u4E00\u201C\u9274\u5B9A\u6EAF\u6E90ID\u8EAB\u4EFD\u8BC1\u2019\u3002 \u8BE5\u7CFB\u7EDF\u5E94\u7528\u201D\u4E00\u7269\u4E00\u7801\u2018\u6EAF\u6E90\u4E13\u5229\u9632\u4F2A\u6280\u672F\uFF0C\u626B\u4E00\u626B\u5C31\u80FD\u968F\u65F6\u4E86\u89E3\u5546\u54C1\u9274\u5B9A\u4FE1\u606F\uFF0C\u6837\u5F0F\u5982\u4E0B\uFF1A") }),
            React.createElement("div", { className: "tag-cert-wrapper" },
                React.createElement("img", { alt: "\u5962\u4F88\u54C1\u9274\u5B9A \u9274\u5B9A\u795E\u5668 \u5C0F\u7A0B\u5E8F \u4E2D\u68C0\u9274\u5B9A", src: "https://file.ilux.ai/nqKlEsQggv7aoeAX3SzBH2NlCtw1ibUu/%E8%87%AA%E5%AE%9A%E4%B9%89%E6%A8%A1%E6%9D%BF.jpg", onClick: function () {
                        return previewImage("https://file.ilux.ai/nqKlEsQggv7aoeAX3SzBH2NlCtw1ibUu/%E8%87%AA%E5%AE%9A%E4%B9%89%E6%A8%A1%E6%9D%BF.jpg", "中检鉴定证书/溯源标签");
                    }, className: "certs" })),
            React.createElement(SectionTitle, { title: "\u5168\u54C1\u7C7B\u652F\u6301", subtitle: React.createElement("div", { style: { textAlign: "center" } },
                    React.createElement("div", null, "\u672F\u4E1A\u6709\u4E13\u653B, \u6BCF\u4E00\u4E2A\u54C1\u7C7B\u90FD\u7531\u4E13\u95E8\u7684\u9274\u5B9A\u5E08\u8D1F\u8D23"),
                    React.createElement("div", null, "\u7BB1\u5305\u00B7\u9996\u9970\u00B7\u670D\u9970\u00B7\u978B\u5C65\u00B7\u8155\u8868\u00B7\u56F4\u5DFE\u00B7\u773C\u955C\u00B7\u76AE\u5E26\u00B7\u53E3\u7EA2")) }),
            React.createElement("div", { className: "appraise-types-container" },
                React.createElement("img", { src: qiniuImageView("https://file.ilux.ai/d850fc8b0fe712ba729e/google-pixel-4-mockup-perspective-right-floating-shadow.png", 1024), className: "types-mock" })),
            React.createElement(SectionTitle, { title: "\u9274\u5B9A\u6848\u4F8B", subtitle: "\u5DF2\u4E3A\u6570\u5341\u4E07\u5BA2\u6237\u63D0\u4F9B\u4E13\u4E1A\u9274\u5B9A\u670D\u52A1" })),
        React.createElement(AppraiseOrderGrid, { appraiseOrders: appraiseOrders }),
        React.createElement(MinappCode, null),
        React.createElement(Affix, { offsetBottom: 0 },
            React.createElement("div", { className: "mobile-hover" },
                React.createElement(React.Fragment, null,
                    React.createElement(Button, { type: "primary", size: "large", onClick: function () {
                            keywordClickThrough();
                            document.location.href = weappOpenLink;
                        } },
                        React.createElement("div", { className: "go-txt" },
                            "\u9A6C\u4E0A\u5F00\u59CB\u9274\u5B9A",
                            React.createElement(IconFont, { name: "icon-success" })),
                        React.createElement("div", { className: "jump-txt" }, "\u70B9\u51FB\u8DF3\u8F6C\u5230\u5FAE\u4FE1\u5C0F\u7A0B\u5E8F")))))));
};
var serverFetch = function (AV) { return __awaiter(void 0, void 0, void 0, function () {
    var server_rpc, p1, r1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                server_rpc = new ServerRPC(AV);
                p1 = server_rpc.call(API.Appraise.Home.get_random_orders({
                    limit: 24,
                }));
                return [4 /*yield*/, Promise.all([p1])];
            case 1:
                r1 = (_a.sent())[0];
                return [2 /*return*/, [
                        appraiseSlice.actions.setAppraiseOrders(r1),
                        globalSlice.actions.setMeta({
                            title: "鉴定神器 - 专业奢侈品鉴定小程序",
                            description: "专业权威的在线奢侈品鉴定平台，由权威鉴定师团队坐镇 + AI人工智能大数据鉴定技术联手打假，随时为您保驾护航! 无需安装APP，微信扫码打开[鉴定神器]小程序，马上鉴定",
                            keywords: "奢侈品鉴定,在线鉴定,包包鉴定,手表鉴定,鞋履鉴定,服饰鉴定,中检鉴定",
                        }),
                    ]];
        }
    });
}); };
Appraise.serverFetch = serverFetch;
export default Appraise;
