import App from "./App"
import { BrowserRouter } from "react-router-dom"
import React from "react"
import { hydrate } from "react-dom"
import { Provider as ReduxProvider } from "react-redux"
import store from "./redux/store"

hydrate(
	<ReduxProvider store={store}>
		<BrowserRouter forceRefresh={true}>
			<App />
		</BrowserRouter>
	</ReduxProvider>,
	document.getElementById("root")
)

if (module.hot) {
	module.hot.accept()
}
