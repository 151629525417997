var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import "./AspectImage.less";
import IconFont from "../IconFont/IconFont";
import { Skeleton } from "antd";
var AspectImage = function (_a) {
    var src = _a.src, _b = _a.heightRatio, heightRatio = _b === void 0 ? "100%" : _b, _c = _a.loadingText, loadingText = _c === void 0 ? "" : _c, _d = _a.isSkeleton, isSkeleton = _d === void 0 ? false : _d, _e = _a.fillMode, fillMode = _e === void 0 ? "cover" : _e, _f = _a.wrapperStyle, wrapperStyle = _f === void 0 ? {} : _f, _g = _a.style, style = _g === void 0 ? {} : _g, children = _a.children;
    return (React.createElement("div", { className: "image-wrapper", style: __assign({ paddingTop: heightRatio || "100%" }, wrapperStyle) },
        isSkeleton ? (React.createElement(Skeleton.Button, { className: "image", active: true })) : src ? (React.createElement(React.Fragment, null,
            React.createElement("img", { src: src, className: "image", style: fillMode ? __assign(__assign({}, style), { objectFit: fillMode }) : style }),
            React.createElement("div", { className: "loading" },
                React.createElement(IconFont, { name: "icon-loading", spin: true, size: "3em", color: "rgba(0,0,0,0.1)" }),
                React.createElement("div", null, loadingText)))) : (React.createElement("div", { className: "image" },
            React.createElement(IconFont, { name: "pic", size: "3em" }))),
        children));
};
export default AspectImage;
