import React from "react";
import ShopShowcaseItem from "../ShopShowcaseItem/ShopShowcaseItem";
import "./ShopShowcaseGrid.less";
import { Button, Modal } from "antd";
import _ from "lodash";
import AspectImage from "../AspectImage/AspectImage";
import { makeWeappShopQrCode } from "../../utils/helpers";
var ShopShowcaseGrid = function (_a) {
    var shopShowcaseItems = _a.shopShowcaseItems, isHomeGrid = _a.isHomeGrid;
    return (React.createElement("div", { className: "shop-case-grid-wrapper" },
        React.createElement("div", { className: "shop-showcase-grid" }, shopShowcaseItems.map(function (item) { return (React.createElement(ShopShowcaseItem, { key: _.get(item, "objectId"), showcaseItem: item })); })),
        React.createElement("div", { className: "view-more-container" }, isHomeGrid ? (React.createElement(Button, { type: "primary", size: "large", href: "/shop" }, "\u66F4\u591A\u95F2\u7F6E\u597D\u8D27")) : (React.createElement(Button, { type: "primary", size: "large", onClick: function () {
                Modal.success({
                    title: "在微信里打开",
                    content: (React.createElement("div", null,
                        React.createElement("div", null, "\u4FDD\u5B58\u5C0F\u7A0B\u5E8F\u7801\u6216\u5FAE\u4FE1\u8F6C\u53D1\u9875\u9762\u7ED9\u81EA\u5DF1\uFF0C\u5728\u5FAE\u4FE1\u5185\u6253\u5F00\u5373\u53EF\u5B89\u5168\u8D2D\u7269\u54E6"),
                        React.createElement(AspectImage, { src: makeWeappShopQrCode({}) }))),
                });
            } }, "\u6253\u5F00\u5C0F\u7A0B\u5E8F\u770B\u66F4\u591A")))));
};
export default ShopShowcaseGrid;
