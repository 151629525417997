import { Button } from "antd";
import _ from "lodash";
import React from "react";
import { createWeAppHtml } from "../../pages/Appraise/Appraise";
import AppraiseOrderItem from "../AppraiseOrderItem/AppraiseOrderItem";
import "./AppraiseOrderGrid.less";
import { isWeChat } from "../../utils/helpers";
var AppraiseOrderGrid = function (_a) {
    var appraiseOrders = _a.appraiseOrders, isHomeGrid = _a.isHomeGrid;
    return (React.createElement("div", null,
        React.createElement("div", { className: "appraise-order-grid" }, appraiseOrders.map(function (order) { return (React.createElement(AppraiseOrderItem, { key: _.get(order, "objectId"), appraiseOrder: order })); })),
        React.createElement("div", { className: "appraise-view-more-container" }, isHomeGrid ? (React.createElement(Button, { type: "primary", size: "large", href: "/appraise" }, "\u66F4\u591A\u9274\u5B9A\u62A5\u544A")) : !isWeChat() ? (
        // <Button
        // 	type="primary"
        // 	size="large"
        // 	onClick={() => {
        // 		qrcodeModal({ platform: "appraise" })
        // 	}}
        // >
        // 	<IconFont name="icon-QRcode" size="1.2em" style={{ marginRight: 4 }} />
        // 	打开小程序了解更多
        // </Button>
        "") : (React.createElement("div", { dangerouslySetInnerHTML: createWeAppHtml() })))));
};
export default AppraiseOrderGrid;
