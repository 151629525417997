var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import AV from "leancloud-storage";
import _ from "lodash";
import moment from "moment";
import API from "../api/api";
import { globalSlice } from "../redux/store";
export var qiniuImageView = function (url, width, height, mode) {
    if (url === void 0) { url = ""; }
    if (width === void 0) { width = 750; }
    if (height === void 0) { height = 0; }
    if (mode === void 0) { mode = 2; }
    return !!url ? url + "?imageView2/" + mode + "/w/" + width + (height != null ? "/h/" + height : "") : "";
};
export var qiniuWaterMark = function (url, watermarkUrlB64, scale) {
    if (scale === void 0) { scale = 0.08; }
    watermarkUrlB64 = watermarkUrlB64 || "aHR0cHM6Ly9maWxlLmlsdXguYWkvMTYwZmZmMTRhYzQ4MTgyYjVhMTcucG5n";
    var cmd = "watermark/1/image/" + watermarkUrlB64 + "/dx/20/dy/20/ws/" + scale;
    if (url.includes("?")) {
        return url + "|" + cmd;
    }
    else {
        return url + "?" + cmd;
    }
};
export var qiniuCmd = function (url, _a) {
    var _b = _a.width, width = _b === void 0 ? 750 : _b, _c = _a.height, height = _c === void 0 ? 0 : _c, _d = _a.mode, mode = _d === void 0 ? 2 : _d, _e = _a.watermarkUrlB64, watermarkUrlB64 = _e === void 0 ? "aHR0cHM6Ly9maWxlLmlsdXguYWkvMTYwZmZmMTRhYzQ4MTgyYjVhMTcucG5n" : _e, _f = _a.watermarkScale, watermarkScale = _f === void 0 ? 0.4 : _f, _g = _a.watermarkPadding, watermarkPadding = _g === void 0 ? 30 : _g;
    if (url) {
        var imageViewCmd = "?imageView2/" + mode + "/w/" + width + (height != null ? "/h/" + height : "") + "|";
        var waterMarkCmd = "watermark/1/image/" + watermarkUrlB64 + "/dx/" + watermarkPadding + "/dy/" + watermarkPadding + "/wst/2/ws/" + watermarkScale;
        if (watermarkUrlB64) {
            url += "" + imageViewCmd + waterMarkCmd;
        }
        else {
            url += imageViewCmd;
        }
        return url;
    }
    else {
        return "";
    }
    !!url ? url + "?imageView2/" + mode + "/w/" + width + (height != null ? "/h/" + height : "") : "";
    return;
};
export var AVDeepJsonify = function (avobject) {
    try {
        var jsobj = {};
        var obj = _.get(avobject, "toJSON") ? avobject.toJSON() : avobject;
        for (var _i = 0, _a = Object.entries(obj); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            if (_.get(value, "toJSON")) {
                jsobj[key] = value.toJSON();
            }
        }
        return obj;
    }
    catch (err) {
        return avobject;
    }
};
export var Logout = function () { return __awaiter(void 0, void 0, void 0, function () {
    var err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, AV.User.logOut()];
            case 1:
                _a.sent();
                return [3 /*break*/, 3];
            case 2:
                err_1 = _a.sent();
                console.error(err_1);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var conditionParse = function (condition) {
    if (!condition) {
        return "无成色";
    }
    if (condition == 100) {
        return "全新";
    }
    else if (condition % 10 == 0) {
        return condition / 10 + "\u6210\u65B0";
    }
    else {
        return condition + "\u65B0";
    }
};
export var DATE_RANGES = {
    all: [moment().subtract(10, "year"), moment()],
    today: [moment().set("hour", 0).set("minute", 0).set("second", 0), moment()],
    month: [moment().startOf("month"), moment().endOf("month")],
    lastMonth: [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
};
var makeQrCode = function (_a) {
    var platform = _a.platform, page = _a.page, scene = _a.scene;
    page = page || "pages/index/index";
    scene = scene || "";
    var url = "http://cloud.ilux.ai/weapp/qrcode?platform=" + platform + "&page=" + page + "&scene=" + scene;
    return url;
};
export var makeWeappShopQrCode = function (_a) {
    var page = _a.page, scene = _a.scene;
    page = page || "pages/index/index";
    scene = scene || "";
    return makeQrCode({ platform: "weapp_shop", page: page, scene: scene });
};
export var makeWeappAppraiseQrCode = function (_a) {
    var page = _a.page, scene = _a.scene;
    page = page || "pages/index/index";
    scene = scene || "";
    return makeQrCode({ platform: "weapp_appraise", page: page, scene: scene });
};
export var signWxJssdk = function (server_rpc, currentUrl) {
    if (currentUrl === void 0) { currentUrl = ""; }
    return __awaiter(void 0, void 0, void 0, function () {
        var appId, url, timestamp, noncestr, jssdkSignature, conf;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    appId = "wxa732334498f72d4a";
                    // var url = _.get(global, "window.location.href", "")
                    if (currentUrl.includes("undefined")) {
                        return [2 /*return*/, null];
                    }
                    url = currentUrl;
                    timestamp = String(Math.round(Date.now() / 1000));
                    noncestr = String(Math.round(Math.random() * 10000000));
                    return [4 /*yield*/, server_rpc.call(API.Web.wxmp_jssdk_signature({
                            noncestr: noncestr,
                            url: url,
                            timestamp: timestamp,
                        }))];
                case 1:
                    jssdkSignature = _a.sent();
                    conf = {
                        debug: false,
                        appId: appId,
                        timestamp: timestamp,
                        nonceStr: noncestr,
                        signature: jssdkSignature,
                        jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"],
                        openTagList: ["wx-open-launch-weapp", "wx-open-launch-app"],
                    };
                    return [2 /*return*/, globalSlice.actions.setWxJssdkConfig(conf)];
            }
        });
    });
};
export var wxConfInit = function (_a) {
    var wxJssdkConfig = _a.wxJssdkConfig, shareTitle = _a.shareTitle, shareDesc = _a.shareDesc, shareImage = _a.shareImage;
    var wx = _.get(global, "window.wx");
    if (!wxJssdkConfig || !wx) {
        console.error("NO WX FOUND");
        console.log("wxJssdkConfig", wxJssdkConfig);
        return;
    }
    wx.config(wxJssdkConfig);
    wx.ready(function () {
        console.log("WXREADY!");
        shareTitle = shareTitle || "iLUX*耍大牌研究所";
        shareDesc = shareDesc || "奢侈品鉴定·正品闲置奢侈品买卖";
        shareImage = shareImage || qiniuImageView("https://file.ilux.ai/845f61aba8512360353e.png", 600);
        var shareLink = location.href + (!location.search ? "?date=" + Date.now() : "&date=" + Date.now());
        wx.onMenuShareAppMessage({
            title: shareTitle,
            desc: shareDesc,
            link: shareLink,
            imgUrl: shareImage,
            success: function () {
                // 设置成功
                console.log("share friend set!");
            },
        });
        wx.onMenuShareTimeline({
            title: shareTitle,
            link: shareLink,
            imgUrl: shareImage,
            success: function () {
                // 设置成功
                console.log("share set");
            },
        });
    });
    wx.error(function (err) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        console.log(err);
    });
};
export var isWeChat = function () {
    return _.get(global, "navigator.userAgent", "").toLowerCase().indexOf("micromessenger") > -1 || typeof _.get(global, "navigator.wxuserAgent") !== "undefined";
};
