import React from "react";
import { Modal, Typography } from "antd";
import AspectImage from "../AspectImage/AspectImage";
import { makeWeappShopQrCode, makeWeappAppraiseQrCode, isWeChat, } from "../../utils/helpers";
import { isMobile } from "react-device-detect";
export var qrcodeModal = function (_a) {
    var platform = _a.platform, page = _a.page, scene = _a.scene, description = _a.description;
    var tip = !isMobile
        ? "微信扫描小程序码"
        : !isWeChat()
            ? "长按二维码 → 分享到微信 → 发给自己"
            : "长按识别二维码";
    var minappName = platform == "shop" ? "耍大牌闪拍" : "鉴定神器";
    var defaultDescription = (React.createElement("div", null,
        "\u5FAE\u4FE1\u626B\u7801\u6216\u641C\u7D22",
        " ",
        React.createElement(Typography.Text, { copyable: true, children: React.createElement("span", { style: { color: "black" } },
                "\"",
                minappName,
                "\"") }),
        " ",
        "\u5C0F\u7A0B\u5E8F"));
    Modal.success({
        icon: null,
        maskClosable: true,
        title: "打开微信小程序",
        content: (React.createElement("div", null,
            isMobile ? React.createElement("div", null, tip) : React.createElement("div", null, "\u5FAE\u4FE1\u626B\u63CF\u4E8C\u7EF4\u7801\u5373\u53EF\u67E5\u770B\u8BE6\u60C5"),
            React.createElement("div", null, description || defaultDescription),
            React.createElement("div", { style: {
                    width: 240,
                    margin: "16px auto 0 auto",
                    borderRadius: 8,
                    overflow: "hidden",
                } },
                React.createElement(AspectImage, { loadingText: "\u6B63\u5728\u751F\u6210\u5C0F\u7A0B\u5E8F\u7801", src: platform == "shop"
                        ? makeWeappShopQrCode({
                            page: page,
                            scene: scene,
                        })
                        : makeWeappAppraiseQrCode({
                            page: page,
                            scene: scene,
                        }) })))),
        onOk: function () { },
        okText: "知道了",
    });
};
var QRModal = function () {
    return React.createElement("div", null);
};
export default QRModal;
