var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import _ from "lodash";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import API, { ServerRPC } from "../../api/api";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import ShopShowcaseGrid from "../../components/ShopShowcaseGrid/ShopShowcaseGrid";
import { shopSlice, globalSlice } from "../../redux/store";
import { ShopHighlights } from "../Home/Home";
import "./Shop.less";
import { wxConfInit, qiniuImageView } from "../../utils/helpers";
var Shop = function (p) {
    var showcaseItems = useSelector(function (state) { return _.get(state, "shop.showcaseItems", []); }) || [];
    var wxJssdkConfig = useSelector(function (state) { return _.get(state, "global.wxJssdkConfig"); });
    useEffect(function () {
        wxConfInit({
            wxJssdkConfig: wxJssdkConfig,
            shareTitle: "耍大牌闪拍",
            shareDesc: "正品二手买卖小程序 | iLux出品",
            shareImage: qiniuImageView("https://file.ilux.ai/5484e850fbe0a14e0b60.png", 500),
        });
    }, [wxJssdkConfig]);
    return (React.createElement("div", { className: "page" },
        React.createElement(SectionTitle, { title: React.createElement("span", null,
                "\u800D\u5927\u724C\u95EA\u62CD",
                React.createElement("span", { style: { fontSize: "0.6em" } }, "\u5C0F\u7A0B\u5E8F")), subtitle: "\u6B63\u54C1\u95F2\u7F6E\u5962\u4F88\u54C1\u7EBF\u4E0A\u4EA4\u6613\u5E73\u53F0\uFF0C\u5EFA\u7ACB\u4E25\u8C28\u7684\u5546\u54C1\u9274\u5B9A\u548C\u4F30\u503C\u4F53\u7CFB\uFF0C\u6240\u552E\u8D27\u54C1\u5747\u901A\u8FC7\u8BA4\u8BC1\u9274\u5B9A\u5E08\u5B9E\u7269\u9274\u5B9A\u3002\u5C0F\u7A0B\u5E8F\u5185\u6216\u5FAE\u4FE1\u4E00\u5BF9\u4E00\u5BA2\u670D\u7BA1\u5BB6\u5168\u7A0B\u8DDF\u8FDB\u670D\u52A1\u3002" }),
        React.createElement(ShopHighlights, null),
        React.createElement(ShopShowcaseGrid, { shopShowcaseItems: showcaseItems })));
};
var serverFetch = function (AV) { return __awaiter(void 0, void 0, void 0, function () {
    var server_rpc, p1, r1, items, brandNames, brandCnNames, allBrandNames, meta;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                server_rpc = new ServerRPC(AV);
                p1 = server_rpc.call(API.Shop.Item.get_shop_showcase_items({
                    page: 0,
                    limit: 1000,
                }));
                return [4 /*yield*/, Promise.all([p1])];
            case 1:
                r1 = (_a.sent())[0];
                items = _.get(r1, "items") || [];
                brandNames = items.map(function (item) { return _.get(item, "brand.name"); });
                brandCnNames = items.map(function (item) { return _.get(item, "brand.cnName"); });
                allBrandNames = brandNames.concat(brandCnNames);
                allBrandNames = allBrandNames
                    .reduce(function (prev, curr) {
                    if (!prev.includes(curr)) {
                        return __spreadArrays(prev, [curr]);
                    }
                    else {
                        return prev;
                    }
                }, [])
                    .filter(function (bn) { return bn; });
                meta = {
                    title: "耍大牌闪拍 正品二手买卖小程序",
                    description: "正品闲置奢侈品线上交易平台，建立严谨的商品鉴定和估值体系，所售货品均通过认证鉴定师实物鉴定。小程序内或微信一对一客服管家全程跟进服务。",
                    keywords: "\u6B63\u54C1\u4E8C\u624B\u4E70\u5356\u5E73\u53F0,\u95F2\u7F6E\u5962\u4F88\u54C1,\u95F2\u7F6E\u5BC4\u5356,\u95F2\u7F6E\u7269\u54C1\u4EA4\u6613,\u4E8C\u624B\u5962\u4F88\u54C1,\u4E8C\u624B\u95F2\u7F6E,\u4E8C\u624B\u5305\u56DE\u6536,\u5962\u4F88\u54C1\u56DE\u6536," + allBrandNames.join(","),
                };
                console.log("meta", meta);
                return [2 /*return*/, [shopSlice.actions.setShowcaseItems(_.get(r1, "items")), globalSlice.actions.setMeta(meta)]];
        }
    });
}); };
Shop.serverFetch = serverFetch;
export default withRouter(Shop);
