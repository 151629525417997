import Appraise from "../pages/Appraise/Appraise";
import Home from "../pages/Home/Home";
import Shop from "../pages/Shop/Shop";
import { qiniuImageView } from "../utils/helpers";
import About from "../pages/About/About";
import Item from "../pages/Shop/Item";
var routes = [
    {
        key: "ilux",
        label: "首页",
        path: "/",
        component: Home,
        exact: true,
    },
    {
        key: "about",
        label: "关于我们",
        path: "/about",
        component: About,
        exact: true,
        hideFromMenu: true,
    },
    {
        key: "appraise",
        label: "鉴定神器",
        path: "/appraise",
        component: Appraise,
        exact: true,
        icon: qiniuImageView("https://file.ilux.ai/468a4b1bf4b72f32862d.png", 40),
    },
    {
        key: "shop",
        label: "闪拍",
        path: "/shop",
        component: Shop,
        exact: true,
        icon: qiniuImageView("https://file.ilux.ai/5484e850fbe0a14e0b60.png", 40),
    },
    {
        key: "shop_item",
        label: "商品",
        path: "/shopitem/:id/:itemName?",
        component: Item,
        exact: true,
        hideFromMenu: true
    },
];
export default routes;
