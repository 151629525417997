import _ from "lodash";
export var useQuerySearch = function (location) {
    var queries = {};
    try {
        var qs = _.clone(decodeURIComponent(location.search)).replace("?", "").split("&");
        qs.forEach(function (q) {
            var _a = q.split("="), k = _a[0], v = _a[1];
            queries[k] = v;
        });
        return queries;
    }
    catch (error) { }
    return {};
};
