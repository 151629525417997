import _ from "lodash";
import React, { useEffect, useState } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import "./App.less";
import MainFrame from "./MainFrame/MainFrame";
import routes from "./MainFrame/routes";
function App() {
    var location = useLocation();
    var history = useHistory();
    var _a = useState(location.key), lastLocationKey = _a[0], setLastLocationKey = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    useEffect(function () {
        var matchedRoute = routes.find(function (r) { return matchPath(location.pathname, r); });
        if (location.key != lastLocationKey && !!_.get(matchedRoute, "component.serverFetch")) {
            setLoading(true);
            window.location.reload(true);
            setLoading(false);
        }
        else {
            setLastLocationKey(location.key);
            setLoading(false);
        }
        setLoading(false);
        if (_.get(global, "window.scrollTo")) {
            window.scrollTo(0, 0);
        }
    }, [location]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "App" },
            React.createElement(MainFrame, null))));
}
export default App;
