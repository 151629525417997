var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var ServerRPC = /** @class */ (function () {
    function ServerRPC(AV) {
        var _this = this;
        this.rpc = function (fn, params) { return new Promise(function () { }); };
        this.call = function (_a) {
            var funcName = _a.funcName, params = _a.params;
            return __awaiter(_this, void 0, void 0, function () {
                var res, err_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this.rpc(funcName, params)];
                        case 1:
                            res = _b.sent();
                            // console.log(`${funcName} res`, res)
                            return [2 /*return*/, res];
                        case 2:
                            err_1 = _b.sent();
                            console.error(err_1);
                            throw err_1;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        this.rpc = AV.Cloud.rpc;
    }
    return ServerRPC;
}());
export { ServerRPC };
var CloudRpc = function (funcName, params) {
    return {
        funcName: funcName,
        params: params,
    };
};
var Platform;
(function (Platform) {
    Platform[Platform["weapp"] = 0] = "weapp";
    Platform[Platform["swan"] = 1] = "swan";
})(Platform || (Platform = {}));
var API = /** @class */ (function () {
    function API() {
    }
    var _a, _b, _c, _d, _e, _f, _g;
    API.call = function (_a) {
        var func = _a.func, setLoading = _a.setLoading, _b = _a.loadingDelay, loadingDelay = _b === void 0 ? 0 : _b, setData = _a.setData, callback = _a.callback;
        return __awaiter(void 0, void 0, void 0, function () {
            var res, err_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (setLoading) {
                            setLoading(true);
                        }
                        else {
                            // Taro.showLoading({
                            // 	title: "loading"
                            // })
                        }
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, func];
                    case 2:
                        res = _c.sent();
                        if (setData) {
                            setData(res);
                        }
                        if (callback) {
                            callback(res);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _c.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        if (setLoading) {
                            setTimeout(function () {
                                setLoading(false);
                            }, loadingDelay);
                        }
                        else {
                            // Taro.hideLoading()
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    API.Web = (_a = /** @class */ (function () {
            function class_1() {
            }
            return class_1;
        }()),
        _a.wxmp_jssdk_signature = function (params) { return CloudRpc("v3:web:common:wxmp_jssdk_signature", params); },
        _a);
    API.Shop = (_b = /** @class */ (function () {
            function class_2() {
            }
            return class_2;
        }()),
        _b.Category = (_c = /** @class */ (function () {
                function class_3() {
                }
                return class_3;
            }()),
            // get_common_data
            _c.change_cateogry_name = function (params) { return CloudRpc("v3:shop:category:change_cateogry_name", params); },
            _c.add_cateogry = function (params) {
                return CloudRpc("v3:shop:category:add_cateogry", params);
            },
            _c.delete_category = function (params) {
                return CloudRpc("v3:shop:category:delete_category", params);
            },
            _c),
        _b.Item = (_d = /** @class */ (function () {
                function class_4() {
                }
                return class_4;
            }()),
            // fetch_item
            _d.fetch_item = function (params) {
                return CloudRpc("v3:shop:item:fetch_item", params);
            },
            // get_shop_showcase_items
            _d.get_shop_showcase_items = function (params) { return CloudRpc("v3:shop:item:get_shop_showcase_items", params); },
            // get_shop_item_photos
            _d.get_shop_item_photos = function (params) { return CloudRpc("v3:shop:item:get_shop_item_photos", params); },
            _d),
        _b);
    API.Appraise = (_e = /** @class */ (function () {
            function class_5() {
            }
            return class_5;
        }()),
        _e.Home = (_f = /** @class */ (function () {
                function class_6() {
                }
                return class_6;
            }()),
            _f.get_orders = function (params) { return CloudRpc("v3:appraise:home:get_orders", params); },
            _f.get_random_orders = function (params) {
                return CloudRpc("v3:appraise:home:get_random_orders", params);
            },
            _f),
        _e.Minapp = (_g = /** @class */ (function () {
                function class_7() {
                }
                return class_7;
            }()),
            _g.weapp_appraise_url_scheme_generate = function (params) { return CloudRpc("v3:mianpp:weapp_appraise_url_scheme_generate", params); },
            _g),
        _e);
    return API;
}());
export default API;
