var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Card, Divider, Affix, Button, Modal } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import API, { ServerRPC } from "../../api/api";
import AspectImage from "../../components/AspectImage/AspectImage";
import IconFont from "../../components/IconFont/IconFont";
import { shopSlice, globalSlice } from "../../redux/store";
import { conditionParse, qiniuImageView, qiniuCmd, isWeChat, wxConfInit, } from "../../utils/helpers";
import "./Item.less";
import { ShopHighlights } from "../Home/Home";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { isMobile } from "react-device-detect";
var Item = function () {
    var shopItem = useSelector(function (state) { return _.get(state, "shop.shopItem"); });
    var shopItemPhotos = useSelector(function (state) { return _.get(state, "shop.shopItemPhotos"); }) || [];
    // console.log(shopItem, shopItemPhotos)
    var objectId = _.get(shopItem, "objectId");
    var name = _.get(shopItem, "name");
    var description = _.get(shopItem, "description");
    var price = _.get(shopItem, "price");
    var displayPrice = "";
    try {
        displayPrice = price.toFixed(0);
    }
    catch (err) { }
    var brand = _.get(shopItem, "brand", {});
    var brandCnName = _.get(brand, "cnName");
    var brandName = _.get(brand, "name");
    var brandId = _.get(brand, "objectId");
    var cover_url = qiniuImageView(_.get(shopItem, "cover_url", ""), 750);
    var condition = conditionParse(_.get(shopItem, "condition", 0));
    var category = _.get(shopItem, "category");
    var wxJssdkConfig = useSelector(function (state) {
        return _.get(state, "global.wxJssdkConfig");
    });
    useEffect(function () {
        wxConfInit({
            wxJssdkConfig: wxJssdkConfig,
            shareTitle: brandName + " " + name,
            shareDesc: name + " \u00A5" + price,
            shareImage: cover_url,
        });
    }, [wxJssdkConfig]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "shop-item-grid" },
            React.createElement("div", { className: "shop-item-page" },
                React.createElement(Card, { className: "shop-item-card", cover: React.createElement(AspectImage, { src: qiniuImageView(_.get(shopItem, "cover_url", "")) }) },
                    React.createElement(Card.Meta, { title: React.createElement("div", null,
                            React.createElement("h3", { className: "brand-name" },
                                brandName,
                                " ",
                                brandCnName),
                            React.createElement("h4", { className: "item-name" }, name)), description: React.createElement("div", { className: "description" },
                            "\u300C",
                            condition,
                            "\u300D",
                            description) }),
                    React.createElement("div", { className: "price-wrapper" },
                        React.createElement(IconFont, { name: "icon-discount" }),
                        React.createElement("span", { className: "price" }, displayPrice)),
                    React.createElement(Divider, null),
                    React.createElement(SectionTitle, { title: "\u800D\u5927\u724C\u95EA\u62CD", subtitle: React.createElement("div", { style: { textAlign: "center" } },
                            React.createElement("div", null, "100%\u6B63\u54C1\u4FDD\u8BC1\u7684\u95F2\u7F6E\u5962\u4F88\u54C1\u4E70\u5356\u5FAE\u4FE1\u5C0F\u7A0B\u5E8F"),
                            React.createElement("div", null, "iLUX\u65D7\u4E0B\u9274\u5B9A\u795E\u5668\u56E2\u961F\u5168\u7A0B\u6B63\u54C1\u62A4\u822A")) }),
                    React.createElement(ShopHighlights, null),
                    React.createElement(Divider, null),
                    React.createElement("div", { className: "shop-item-photos-grid" }, shopItemPhotos.map(function (p) { return (React.createElement("img", { alt: "" + brandName + brandCnName + " " + name + " " + description, key: _.get(p, "file.objectId"), className: "shop-item-photo", src: qiniuCmd(_.get(p, "file.url"), {
                            width: 750,
                            watermarkUrlB64: "aHR0cHM6Ly9maWxlLmlsdXguYWkvNjFjZTk0ZjVhMWZlZWY0MDVmMTEucG5n",
                            watermarkScale: 0.3,
                            watermarkPadding: 20,
                        }) })); })))),
            React.createElement("div", { className: "right-col" },
                React.createElement(Affix, { offsetTop: 100 },
                    React.createElement(React.Fragment, null,
                        React.createElement("div", null, "\u5FAE\u4FE1\u626B\u63CF\u4E8C\u7EF4\u7801\u67E5\u770B\u8D2D\u4E70\u8BE6\u60C5"),
                        React.createElement("img", { src: _.get(shopItem, "weappQR.url"), style: { borderRadius: 8, marginTop: 8 }, width: 200 }),
                        React.createElement("div", null,
                            "\u63D0\u793A:",
                            " ",
                            !isWeChat()
                                ? "长按二维码 → 分享到微信 → 发给自己"
                                : "长按识别二维码"))))),
        React.createElement(Affix, { offsetBottom: 0, className: "buy-affix" },
            React.createElement("div", { className: "buy-btn-wrapper" },
                React.createElement(Button, { type: "primary", size: "large", onClick: function () {
                        Modal.success({
                            icon: null,
                            maskClosable: true,
                            title: brandName + " " + name,
                            content: (React.createElement("div", null,
                                React.createElement("div", null, "\u5FAE\u4FE1\u626B\u63CF\u4E8C\u7EF4\u7801\u67E5\u770B\u8D2D\u4E70\u8BE6\u60C5"),
                                React.createElement("div", { style: {
                                        width: 240,
                                        margin: "16px auto 0 auto",
                                        borderRadius: 8,
                                        overflow: "hidden",
                                    } },
                                    React.createElement(AspectImage, { loadingText: "\u6B63\u5728\u751F\u6210\u5C0F\u7A0B\u5E8F\u7801", src: _.get(shopItem, "weappQR.url") })),
                                isMobile ? (React.createElement("div", null,
                                    "\u63D0\u793A:",
                                    " ",
                                    !isWeChat()
                                        ? "长按二维码 → 分享到微信 → 发给自己"
                                        : "长按识别二维码")) : (""))),
                            onOk: function () { },
                            okText: "知道了",
                        });
                    } },
                    React.createElement(IconFont, { name: "icon-cart-Empty", size: "1.3em", style: { marginRight: 4 } }),
                    " ",
                    "\u6211\u8981\u4E70")))));
};
var serverFetch = function (AV, req_path) { return __awaiter(void 0, void 0, void 0, function () {
    var resetShopItem, item_id, server_rpc, p1, p2, _a, r1, r2, meta, error_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                resetShopItem = function () {
                    return [
                        shopSlice.actions.setShopItem(null),
                        shopSlice.actions.setShopItemPhotos(null),
                    ];
                };
                _b.label = 1;
            case 1:
                _b.trys.push([1, 3, , 4]);
                if (!req_path) {
                    return [2 /*return*/, resetShopItem()];
                }
                item_id = parseInt(req_path.split("shopitem/")[1]);
                if (!item_id) {
                    return [2 /*return*/, resetShopItem()];
                }
                server_rpc = new ServerRPC(AV);
                p1 = server_rpc.call(API.Shop.Item.fetch_item({
                    num_id: item_id,
                }));
                p2 = server_rpc.call(API.Shop.Item.get_shop_item_photos({
                    shop_item_id: item_id,
                }));
                return [4 /*yield*/, Promise.all([p1, p2])];
            case 2:
                _a = _b.sent(), r1 = _a[0], r2 = _a[1];
                meta = {
                    title: _.get(r1, "brand.name") + " " + _.get(r1, "brand.cnName") + " " + _.get(r1, "name"),
                    description: "" + _.get(r1, "description"),
                    keywords: _.get(r1, "brand.name") + "," + _.get(r1, "brand.cnName") + "," + _.get(r1, "name") + ",\u6B63\u54C1\u4E8C\u624B\u4E70\u5356\u5E73\u53F0,\u95F2\u7F6E\u5962\u4F88\u54C1,\u95F2\u7F6E\u5BC4\u5356,\u95F2\u7F6E\u7269\u54C1\u4EA4\u6613,\u4E8C\u624B\u5962\u4F88\u54C1,\u4E8C\u624B\u95F2\u7F6E,\u4E8C\u624B\u5305\u56DE\u6536,\u5962\u4F88\u54C1\u56DE\u6536",
                };
                // console.log("r1", r1, meta)
                return [2 /*return*/, [
                        shopSlice.actions.setShopItem(r1),
                        shopSlice.actions.setShopItemPhotos(r2),
                        globalSlice.actions.setMeta(meta),
                    ]];
            case 3:
                error_1 = _b.sent();
                return [2 /*return*/, resetShopItem()];
            case 4: return [2 /*return*/];
        }
    });
}); };
Item.serverFetch = serverFetch;
export default Item;
