var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { configureStore, combineReducers, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
/*=========================================================================================================
Slices
=========================================================================================================*/
export var globalSlice = createSlice({
    name: "global",
    initialState: {
        wxJssdkConfig: null,
        meta: {
            title: "iLux | 正品二手奢侈品鉴定买卖平台",
            description: "iLux 线上闲置奢侈品交易鉴定平台，坐拥权威专业的鉴定师团队及行内庞大数据库，建立效率极高并有保障的商品流转体系，为用户提供鉴定服务和正品优质货源。",
            keywords: "奢侈品鉴定,正品二手买卖平台,闲置奢侈品,闲置寄卖,闲置物品交易,二手奢侈品,二手闲置,二手包回收,鉴定师,奢侈品回收",
        },
    },
    reducers: {
        setWxJssdkConfig: function (state, action) { return (__assign(__assign({}, state), { wxJssdkConfig: action.payload })); },
        setMeta: function (state, action) { return (__assign(__assign({}, state), { meta: action.payload })); },
    },
});
export var homeSlice = createSlice({
    name: "home",
    initialState: {
        shopShowcaseItems: [],
        appraiseOrders: [],
    },
    reducers: {
        setShopShowcaseItems: function (state, action) { return (__assign(__assign({}, state), { shopShowcaseItems: action.payload })); },
        setAppraiseOrders: function (state, action) { return (__assign(__assign({}, state), { appraiseOrders: action.payload })); },
    },
});
export var shopSlice = createSlice({
    name: "shop",
    initialState: {
        showcaseItems: [],
        shopItem: null,
        shopItemPhotos: [],
    },
    reducers: {
        setShowcaseItems: function (state, action) { return (__assign(__assign({}, state), { showcaseItems: action.payload })); },
        setShopItem: function (state, action) { return (__assign(__assign({}, state), { shopItem: action.payload })); },
        setShopItemPhotos: function (state, action) { return (__assign(__assign({}, state), { shopItemPhotos: action.payload })); },
    },
});
export var appraiseSlice = createSlice({
    name: "appraise",
    initialState: {
        appraiseOrders: [],
        // weappOpenLink: "weixin://dl/business/?t=SUZmRO4TYRm"
        weappOpenLink: ""
    },
    reducers: {
        setAppraiseOrders: function (state, action) { return (__assign(__assign({}, state), { appraiseOrders: action.payload })); },
        setWeappOpenLink: function (state, action) { return (__assign(__assign({}, state), { weappOpenLink: action.payload })); },
    },
});
/*=========================================================================================================
Store
=========================================================================================================*/
export var createStore = function (ssr) {
    return configureStore({
        reducer: combineReducers({
            global: globalSlice.reducer,
            home: homeSlice.reducer,
            shop: shopSlice.reducer,
            appraise: appraiseSlice.reducer,
        }),
        preloadedState: ssr ? {} : _.get(global, "window.REDUX_DATA", {}),
    });
};
var store = createStore(false);
export default store;
