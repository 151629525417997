import React from "react";
import { Typography, Card } from "antd";
import "./About.less";
var About = function () {
    return (React.createElement("div", { className: "page" },
        React.createElement(Card, null,
            React.createElement("h1", null, "iLUX*\u800D\u5927\u724C\u7814\u7A76\u6240"),
            React.createElement(Typography.Paragraph, null, "\u5E7F\u5DDE\u7231\u4E3D\u793E\u79D1\u6280\u6709\u9650\u516C\u53F8 (\u7B80\u79F0iLux\u800D\u5927\u724C\u7814\u7A76\u6240) \u662F\u4E00\u5BB6\u4E13\u6CE8\u4E8E\u5962\u4F88\u54C1\u4E8C\u624B\u8F6C\u552E\u4EA4\u6613\u7684\u4E92\u8054\u7F51\u516C\u53F8\u3002"),
            React.createElement(Typography.Paragraph, null, "\u6211\u4EEC\u76EE\u524D\u62E5\u6709\u201D\u9274\u5B9A\u795E\u5668\u201D\u548C\u201C\u800D\u5927\u724C\u95EA\u62CD\u201D\u4E24\u6B3E\u5C0F\u7A0B\u5E8F\uFF0C\u5206\u522B\u4E3A\u4FDD\u771F\u548C\u4EA4\u6613\u8FC7\u7A0B\u63D0\u4F9B\u670D\u52A1\u3002"),
            React.createElement(Typography.Paragraph, null, "\u6211\u4EEC\u7684\u613F\u666F\u662F\u4EE5AI\u9274\u5B9A\u6280\u672F+\u5927\u6570\u636E+\u4E92\u8054\u7F51\u6280\u672F\u4ECE\u6E90\u5934\u6839\u672C\u675C\u7EDD\u5047\u8D27\uFF0C\u4E3A\u4EBA\u4EEC\u521B\u9020\u4E00\u4E2A\u653E\u5FC3\u73CD\u54C1\u6709\u4FDD\u8BC1\u7684\u5962\u4F88\u54C1\u4EA4\u6613\u6D88\u8D39\u73AF\u5883\u3002"),
            React.createElement(Typography.Paragraph, null,
                "\u5FAE\u4FE1\u5BA2\u670D: ",
                React.createElement(Typography.Text, { copyable: true }, "iluxai")),
            React.createElement(Typography.Paragraph, null, "\u8054\u7CFB\u7535\u8BDD: 13533419417"),
            React.createElement(Typography.Paragraph, null, "\u8054\u7CFB\u5730\u5740: \u5E7F\u5DDE\u5E02\u6D77\u73E0\u533A\u5317\u5C9B\u521B\u610F\u56EDA11\u680B"))));
};
export default About;
