var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import _ from "lodash";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import API, { ServerRPC } from "../../api/api";
import AppraiseOrderGrid from "../../components/AppraiseOrderGrid/AppraiseOrderGrid";
import Highlights from "../../components/Highlights/Highlights";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import ShopShowcaseGrid from "../../components/ShopShowcaseGrid/ShopShowcaseGrid";
import { homeSlice } from "../../redux/store";
import "./Home.less";
import { wxConfInit } from "../../utils/helpers";
export var AppraiseHighlights = function () { return (React.createElement(Highlights, { highlightIcons: [
        {
            icon: "icon-shouquanshu",
            title: "权威鉴定团队",
        },
        {
            icon: "icon-dashuju",
            title: "智能大数据",
        },
        {
            icon: "icon-anli",
            title: "10万+成功案例",
        },
        {
            icon: "icon-zhongjianrenzheng",
            title: "中检认证",
        },
        {
            icon: "icon-torri",
            title: "日本奢协认证",
        },
        {
            icon: "icon-gongan",
            title: "公检法特邀鉴定师",
        },
    ] })); };
export var ShopHighlights = function () { return (React.createElement(Highlights, { highlightIcons: [
        {
            icon: "icon-zhengpin",
            title: "100%正品保证",
        },
        {
            icon: "icon-shouquanshu",
            title: "权威鉴定护航",
        },
        {
            icon: "icon-zujian",
            title: "省心顺丰包邮",
        },
    ] })); };
var Home = function () {
    var shopShowcaseItems = useSelector(function (state) { return _.get(state, "home.shopShowcaseItems", []); }) || [];
    var appraiseOrders = useSelector(function (state) { return _.get(state, "home.appraiseOrders", []); }) || [];
    var wxJssdkConfig = useSelector(function (state) { return _.get(state, "global.wxJssdkConfig"); });
    useEffect(function () {
        wxConfInit({
            wxJssdkConfig: wxJssdkConfig,
        });
    }, [wxJssdkConfig]);
    return (React.createElement("div", { style: { padding: 0 } },
        React.createElement("div", { className: "page" },
            React.createElement(SectionTitle, { title: "\u9274\u5B9A\u795E\u5668", subtitle: "\u6743\u5A01\u5728\u7EBF\u5962\u4F88\u54C1\u9274\u5B9A\u670D\u52A1 \u5DF2\u4E3A\u6570\u5341\u4E07\u5BA2\u6237\u63D0\u4F9B\u9274\u5B9A\u670D\u52A1" }),
            React.createElement(AppraiseHighlights, null)),
        React.createElement(AppraiseOrderGrid, { appraiseOrders: appraiseOrders, isHomeGrid: true }),
        React.createElement("div", { className: "page" },
            React.createElement(SectionTitle, { title: "\u95F2\u7F6E\u95EA\u62CD", subtitle: "\u5168\u65B0\u597D\u8D27\u4EFB\u4F60\u9009" }),
            React.createElement(ShopHighlights, null),
            React.createElement(ShopShowcaseGrid, { shopShowcaseItems: shopShowcaseItems, isHomeGrid: true }))));
};
var serverFetch = function (AV, currentUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var server_rpc, p1, p2, _a, r1, r2;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                console.log("currentUrl", currentUrl);
                server_rpc = new ServerRPC(AV);
                p1 = server_rpc.call(API.Shop.Item.get_shop_showcase_items({
                    page: 0,
                    limit: 24,
                }));
                p2 = server_rpc.call(API.Appraise.Home.get_orders({
                    page: 0,
                    limit: 12,
                    count_payloads: true,
                }));
                return [4 /*yield*/, Promise.all([p1, p2])];
            case 1:
                _a = _b.sent(), r1 = _a[0], r2 = _a[1];
                return [2 /*return*/, [homeSlice.actions.setShopShowcaseItems(_.get(r1, "items")), homeSlice.actions.setAppraiseOrders(r2)]];
        }
    });
}); };
Home.serverFetch = serverFetch;
export default Home;
